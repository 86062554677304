export const uk = {
  nav: {
    content: "Контент",
    documents: "Документи",
    tests: "Тести",
    quiz: "Опитування",
    courses: "Курси",
    category: "Категорії документів",
  },
  common: {
    basePage: "Стартова сторінка додатку",
    list: "Список",
    create: "Створити",
    edit: "Редагувати",
    cancel: "Скасувати",
    active: "Активний",
    blocked: "Заблокований",
    archive: "Архів",
    details: "Деталі",
    hide: "Приховати",
    publish: "Опублікувати",
    delete: "Видалити",
    date: "Дата",
    noData: "Немає даних",
    restore: "Відновити",
    copy: "Копіювати",
    save: "Зберегти",
    upload: "Завантажити",
    deleteAll: "Видалити всі",
    roles: 'Ролі',
    activeTab: 'Активні',
    archiveTab: 'Архів',
    fillAllFields: "Заповніть всі обов'язкові поля",
    watch: 'Переглянути',
    tooMuchSymbols: 'Перевищено максимальну кількість символів',
    editAccess: "Редагувати доступи",
    editAssign: "Редагувати призначених",
    reAssign: "Переназначити",
    assign: "Призначення",
    access: "Доступи",
    assignDate: "Дата призначення",
    actions: "Дії",
    emptyState: "Список порожній",
    emptyStateAccess: "Тут буде відображено список доступів, але зараз він порожній. Змініть пошуковий запит.",
    emptyStateAssign: "Тут буде відображено список призначень, але зараз він порожній. Змініть пошуковий запит.",
    add: "Додати"
  },
  auth: {
    phoneNumber: "Номер телефону",
    password: "Пароль",
    enter: "Увійти",
    forgetPassword: "Забули пароль",
    recoverPassword: "Відновити пароль",
    ok: "OK",
    show: "Показати",
    hide: "Скрити",
    newPassword: "Новий пароль",
    confirmPassword: "Повторіть пароль",
    saveNewPassword: "Зберегти новий пароль",
    messages: {
      phoneValidation: "Вказано не всі цифри у номері телефону",
      passwordValidation: "Пароль повинен бути не менше 8 символів, мати хоча б один спеціальний символ, одну заголовну літеру та одну цифру.",
      forgetPassword: "Забули пароль?",
      loginError: "неправильний номер телефону або пароль",
      wrongData: "Невірний логін або пароль",
      smsSent: "Смс зі старим паролем надіслано на ваш номер телефону",
      sendAgain: "Надіслати повторно",
      userNotFound: "Користувач не знайдений",
      threeAttempts: "Ви використали 3 спроби. Спробуйте пізніше.",
      phoneIsRequired: "Поле номер телефону обовʼязкове",
      passIsRequired: "Поле пароль обовʼязкове",
      passIsNotMatch: "Паролі не співпадають",
      passChangedSuccessfully: "Пароль успішно змінено",
    }
  },
  tables: {},
  forms: {},
  users: {
    users: "Користувачі",
    passwordChanged: "Пароль успішно змінено",
    passwordChangedError: "Виникла помилка зміни пароля",
    minPassLength: "Пароль повинен мати щонайменше 2 символи",
    usersList: "Лист користувачів",
    userDetail: "Деталі користувача",
    settings: "Налаштування",
    city: 'Місто',
    position: 'Посада',
    birthday: 'Дата народження',
    daysInCompany: 'Працює у компанії',
    phone: 'Номер телефону',
    status: 'Статус',
    email: 'Email',
    handleUsers: "Управління користувачами",
    searchUser: "Пошук користувача",
    structure: "Структура підприємства",
    companyAdministration: "Адміністрація компанії",
    editUser: "Редагувати користувача",
    role: "Роль",
    userRoles: "Ролі користувача",
    permissions: "Доступи",
    all: "Всі",
    interns: "Стажери",
    pizzaMade: "Виготовлено піц",
    name: "Ім'я",
    secondName: "Прізвище",
    emptyList: "Список порожній",
    emptyListDesc: "Тут буде відображено список користувачiв, але зараз він порожній. Змініть пошуковий запит.",
    ordersHistory: {
      ordersHistory: "Iсторія замовлень",
      new: "Нові",
      processed: "Оброблені",
      searchOrderByNumber: "Пошук за номером замовлення",
      points: "балів",
      units: "шт.",
      closeOrder: "Закрити замовлення",
      active: "Активні",
      hidden: "Приховані",
      documentCategory: "Категорії документів",
      knowledgeCategory: "Категорії Бази знань",
      emptyListTitle: 'Список порожній',
      emptyListDescription: 'Тут буде відображено історію замовлень користувача, коли він замовить хоча б один товар з магазину.'
    },
    studyHistory: {
      studyHistory: "Iсторія навчання",
      rating: "Рейтинг",
      exercise: "Завдання",
      type: "Тип",
      assignmentDate: "Дата призначення",
      completionDate: "Дата виконання",
      completionTermin: "Термін виконання",
      result: "Результат",
      emptyList: "Список порожній",
      emptyListDesc: "Тут буде відображено історію навчання користувача, коли він завершить хоча б одне завдання.",
      searchByTaskName: "Пошук за назвою завдання",
      completed: "Завершено",
      inProgress: "В процесі",
      overdue: "Прострочено",
      familiarize: "Необхідно ознайомитись",
      points: "балів",
      aboutBadge: "Про бейдж",
      assign: "Призначено",
      failed: "Провалено",
    }
  },
  category: {
    documentCategory: "Категорії документів",
    editCategory: "Редагувати категорію",
    searchByCategoryName: "Пошук за назвою категорії",
    createCategory: "Створити категорію",
    categoryTitle: "Заголовок категорії",
    parentCategory: "Батьківська категорія",
    makeCategoryHidden: "Зробити категорію прихованою",
    showCategory: "Відобразити категорію в боковому меню Бази знань",
    uploadImage: "Завантажити обкладинку",
    emptyListTitle: "Список порожній",
    maxSize500: "Максимальний розмір файлу - 500 КВ.",
    emptyListDesc: "Тут буде відображено список категорій документів, але зараз він порожній. Натисніть кнопку “Створити” або змініть пошуковий запит.",
    messages: {
      categoryUpdated: "Категорію оновлено",
      categoryCreated: "Категорія створена",
    }
  },
  documents: {
    documents: "Документи",
    createDocument: "Створити документ",
    editDocument: "Редагувати документ",
    listDocuments: "Список документів",
    searchByDocumentName: "Пошук за назвою документу",
    active: "Активні",
    all: "Всі",
    page: "Сторінка",
    adaptivePage: "Адаптивна сторінка",
    file: "Файл",
    video: "Відео",
    link: "Посилання",
    htmlPage: "HTML-сторінка",
    gallery: "Галерея",
    available: "Доступний",
    hidden: "Прихований",
    publish: "Опублікований",
    notPublish: "Не опублікований",
    applyFilters: "Застосувати фільтри",
    cancelFilters: "Скасувати фільтри",
    category: "Категорія",
    type: "Тип",
    status: "Статус",
    initiator: "Ініціатор документу",
    uploadReport: "Завантажити звіт",
    title: "Назва",
    definePermission: "Доступ до документу",
    assign: "Призначити",
    inform: "Повідомити",
    content: "Вміст",
    addFile: "Додати файл",
    emptyListTitle: "Список порожній",
    textVideo: "Текст відео",
    contentPage: "Вміст сторінки",
    uploadFromComputer: "Завантажити з компьютера",
    emptyListDesc: "Тут буде відображено список документів, але зараз він порожній. Натисніть кнопку “Створити” або змініть пошуковий запит.",
    imagesDNDTitleP1: "Клацніть, щоб завантажити",
    imagesDNDTitleP2: "або перетягніть",
    imagesDNDSubtitle: "Максимальний розмір файлу - 1 Мб.",
    maxSizeFile: 'Максимальний розмір файлу - 1 Мб',
    maxSizeFileVideo: 'Максимальний розмір файлу - 20 МБ.',
    image: "Файл або зображення",
    await: "очікуйте, будь ласка...",
    maxSizeText: "Текст повинен містити не більше 8000 символів",
    accessToUser: "Доступ для користувачів",
    accessToDepartment: "Доступ для департаментів",
    accessToPosition: "Доступ за посадою",
    assignToUser: "Призначити на користувачів",
    assignToDepartment: "Призначити на департаменти",
    assignToPosition: "Призначити за посадою",
    messages: {
      documentUpdated: "Документ оновлено",
      documentRemoved: "Документ видалено",
      documentCreated: "Документ створений",
      tooManySymbols: "Перевищено максимальну кількість символів"
    }
  },
  adverts: {
    adverts: "Оголошення",
    createAdvert: "Створення оголошення",
    editAdvert: "Редагування оголошення",
    advertList: "Список оголошень",
    news: "Новина",
    newsCeo: "CEO Новина",
    promo: "Акція",
    event: "Подія",
    type: "Тип",
    theme: "Тема",
    initiator: "Ініціатор оголошення",
    permission: "Доступ до оголошення",
    familiarization: "Ознайомлення",
    cover: "Обкладинка",
    startEvent: "Дата події",
    endEvent: "Можливість голосувати до",
    fileTooBig: "Максимальний розмір файлу - 1 Мб",
    fillAllFields: "Заповніть всі обов'язкові поля",
    emptyListTitle: "Список порожній",
    forKnowledgeBase: "для категорії Бази знань",
    emptyListDesc: "Тут буде відображено список оголошень, але зараз він порожній. Натисніть кнопку “Створити” або змініть пошуковий запит.",
    messages: {
      advertUpdated: "Оголошення оновлено",
      advertCreated: "Оголошення створено",
      advertRemoved: "Оголошення видалено",
      searchByAdvertName: "Пошук за назвою оголошення",
    }
  },
  test: {
    testList: "Список тестів",
    searchTestByTitle: "Пошук за назвою тесту",
    emptyList: "Список порожній",
    emttyStateDesc: "Тут буде відображено список тестів, але зараз він порожній. Натисніть кнопку “Створити” або змініть пошуковий запит.",
    createTest: "Створити тест",
    editTest: "Редагувати тест",
    theme: "Тема",
    badge: "Бейдж",
    initiator: "Ініціатор",
    accessToTest: "Доступ до тесту",
    deadline: "Дедлайн",
    attemptsAmount: "Кількість спроб",
    minPoints: "Поріг проходження (%)",
    points: "Бали за проходження",
    shortlyAboutTest: "Коротко про тест",
    addQuestion: "Додати питання",
    createQuestion: "Створити питання",
    editQuestion: "Редагувати питання",
    type: "Тип",
    title: "Заголовок",
    allPoints: "Вага питання (бали)",
    addReply: "Додати відповідь",
    uploadFile: "Завантажити файл",
    testInitiator: "Ініціатор тесту",
    questions: "Питання",
    fileTooBig: "Максимальний розмір файлу - 1 MB.",
    needTwoQuestions: "Необхідно додати хоча б дві відповіді",
    typeSingle: "Одиночне",
    typeMultiple: "Множинне",
    typeOrder: "Упорядкування",
    typeFree: "Вільна відповідь",
    typeScale: "По шкалі",
    chooseCorrectReply: "Оберіть правильну відповідь",
    duration: "Час на виконання (днiв)",
    message: {
      testUpdated: "Тест змінено",
      testCreated: "Тест створено",
      testRemoved: "Тест видалено",
      questionRemoved: "Питання видалено",
      questionUpdated: "Питання збережено",
      questionAdded: "Питання створено",
      helperTextForAddingQuestion: '(щоб додати питання, попередньо натисніть кнопку "Створити")',
      needAddOneQuestion: "Необхідно додати хоча б одне запитання",
      needQuestionForReplies: '(щоб додати відповідь, попередньо натисніть кнопку “Створити“)'
    }
  },
  backup: {
    backup: "Резервне копіювання",
    backupList: "Історія резервних копій",
    createBackUp: "Зробити резервне копіювання",
    restoreBackUp: "Відновити резервну копію",
    enterKey: "Введіть ID",
    backupKey: "Ключ резервної копії",
    backupId: "ID резервної копії",
    date: "Дата проведення",
    messages: {
      backupCreated: "Резервна копія створена",
      backupRestored: "Дані відновлено",
    }
  },
  badge: {
    navBar: {
      title: "Гейміфікація",
      list: "Список бейджів",
      create: "Створити"
    },
    messages: {
      delete: "Бейдж успішно видалений"
    },
    tooltip: {
      delete: "Видалити",
      edit: "Редагувати"
    },
    tabs: {
      active: "Активні",
      archive: "Архів"
    },
    badges: {
      title: "Список бейджів",
      buttonCreate: "Створити"
    }
  },
  roles: {
    title: {
      rolesList: 'Список ролей користувачів',
      createRole: 'Створити роль',
      editRole: 'Редагувати роль'
    },
    messages: {
      roleUpdated: "Роль оновлено",
      roleCreated: "Роль створено",
      roleRemoved: "Роль видалено",
    },
    emptyListTitle: 'Список порожній',
    emptyListDescription: 'Тут буде відображено список ролей користувачів, але зараз він порожній. Натисніть кнопку “Створити” або змініть пошуковий запит.',
    name: 'Назва',
    permissions: 'Доступи'
  },
  appPage: {
    navBar: {
      title: "Сторінки застосунку",
      list: "Список",
      create: "Створити"
    },
    title: {
      list: 'Список сторінок застосунку',
      create: 'Створення сторінки застосунку',
      edit: 'Редагування сторінки застосунку',
      maxDescriptionLength: "Перевищено максимальну кількість символів (255)"
    },
    messages: {
      updated: "Сторінку оновлено",
      created: "Сторінку створено",
      removed: "Сторінку видалено",
    },
    form: {
      name: 'Заголовок сторінки',
      description: 'Вміст',
      images: 'Іконка',
    },
    show: 'Переглянути',
    emptyListTitle: 'Список порожній',
    emptyListDescription: 'Тут буде відображено список сторінок застосунку, але зараз він порожній. Натисніть кнопку “Створити” або змініть пошуковий запит.'
  },
  notification: {
    notification: "Сповіщення",
    notificationList: "Список сповіщень",
    emptyListTitle: "Список порожній",
    emptyListDesc: "Тут буде відображено список сповіщень, але зараз він порожній. Натисніть кнопку “Створити” або змініть пошуковий запит.",
    readyForSending: "Очікує відправки",
    sended: "Відправлено",
    archive: "Архів",
    timeIsSet: 'Час встановлено!',
    invalidLink: 'Посилання не коректне',
    link: "Посилання",
    image: "Зображення",
    fillFields: "Заповніть всі обов'язкові поля",
    pushCreated: "Сповіщення створено",
    createPush: "Створення сповіщення",
    all: 'Всі',
    user: 'Користувач',
    region: 'Місто',
    status: 'Cтатус',
    group: "Група користувачiв",
    audience: "Аудиторія",
    country: "Країна",
    userId: "ID користувача",
    searchUsers: "Пошук користувача за ім\'ям",
    title: "Заголовок",
    enterTitle: "Введіть заголовок",
    text: "Зміст",
    enterText: "Введіть текст",
    imageUrl: "URL зображення",
    linkTo: "Посилання",
    imageDetail: "Формати посилання, що підтримуються - .jpg, .png",
    putOff: 'Відкласти сповіщення',
    timeSending: 'Час сповіщення',
    chooseTime: 'Виберіть дату та час (не більше 21 дня)',
    addImage: "Додати заображення",
    removeImage: "Видалити зображення",
    updatePush: 'Редагування сповіщення',
    pushUpdated: "Сповіщення оновлено",
    pushDeleted: "Сповіщення видалено",
    pushIsSend: "Сповіщення відправлено",
    pushDetails: "Деталі сповіщення",
    ready: "Очікує відправлення",
    completed: "Відправлено",
    error: "Архів",
    users: "Користувачі",
    send: "Відправити",
    preview: "Огляд сповіщення",
    role: "Роль",
    maxSizeFile: "Максимальний розмір файлу - 1 MB.",
    imageUrlNotCorrect: "URL зображення некоректне",
    linkNotCorrect: "Посилання некоректне",
    navBar: {
      list: "Лист"
    },
    message: {
      notificationRemoved: "Сповіщення видалено"
    },
  },
  shop: {
    navBar: {
      title: "Магазин",
      list: "Список товарів",
      ordersList: "Список замовлень"
    },
    title: {
      create: 'Створення товару',
      edit: 'Редагування товару'
    },
    messages: {
      updated: "Продукт оновлено",
      created: "Продукт створено",
      removed: "Продукт видалено",
      maxSizeFile: 'Файл занадто великий. Максимальний розмір файлу - 1 МБ.',
      maxDescriptionLength: 'Перевищено максимальну кількість символів (500)',
      maxNameLength: 'Перевищено максимальну кількість символів (255)',

    },
    form: {
      name: 'Назва',
      description: 'Опис',
      images: 'Обкладинка',
      price: 'Вартість',
      amount: 'Кількість',
      publish: 'Орублікувати',
      imagesDNDSubtitle: "Максимальний розмір зображення – 1 мб.",
    },
    amount: ' шт.',
    points: ' балів',
    show: 'Переглянути',
    emptyListTitle: 'Список порожній',
    emptyListDescription: 'Тут буде відображено список товарів, але зараз він порожній. Натисніть кнопку "Створити" або змініть пошуковий запит.'
  },
  order: {
    title: {
      ordersHistory: 'Історія замовлень',
      searchOrder: 'Пошук за номером замовлення',
      new: 'Нові',
      processed: 'Оброблені',
      closeOrder: 'Закрити замовлення',
      orderClosed: "Замовлення закрито"
    },
    messages: {
      updated: "Замовлення оновлено",
    },
    emptyListTitle: 'Список порожній',
    emptyListDescription: "Тут буде відображено історію замовлень, коли буде придбано хоча б один товар із магазину."
  },
  quiz: {
    emptyListTitle: "Список порожній",
    emptyListDesc: "Тут буде відображено список опитувань, але зараз він порожній. Натисніть кнопку “Створити” або змініть пошуковий запит.",
    quizList: "Список опитувань",
    searchByName: "Пошук за назвою опитування",
    createQuiz: "Створити опитування",
    editQuiz: "Редагувати опитування ",
    assign: "Призначити",
    addQuestion: "Додати питання",
    publish: "Опублікувати",
    inform: "Повідомити",
    nameLessQuiz: "Анонімне опитування",
    theme: "Тема",
    initiator: "Ініціатор опитування",
    accessToQuiz: "Доступ до опитування",
    shortlyAboutQuiz: "Коротко про опитування",
    message: {
      quizUpdated: "Опитування оновлено",
      quizCreated: "Опитування створено",
      quizRemoved: "Опитування видалено",
      questionAdded: "Питання створено",
      questionUpdated: "Питання збережено",
    }
  },
  feedback: {
    inWork: "В роботі",
    takeToDevelop: "Взяли у розробку",
    refusal: "Відмова",
    duplicate: "Дубль",
    pause: "Пауза",
    notEnoughInformation: "Недостатньо інформації",
    embodied: "Втілена",
    generalQuestionList: "Список загальних питань",
    searchByName: "Пошук за ПІБ",
    chooseDecision: "Вибрати рішення",
    assignResponsible: "Призначити відповідального",
    emptyList: "Список порожній",
    ideaList: "Список ідей",
    anonymousComplaint: "Анонімна скарга",
    anonymousComplaintList: "Список анонімних скарг",
    commonQuestion: "Загальні питання",
    feedback: "Зворотній зв'язок",
    isAnIdea: "Є ідея",
    ideaDesc: "Опис ідеї",
    ideaImpact: "На що впливає ідея",
    examples: "Приклади",
    takePart: "Бажаю взяти участь в реалізації",
    generalQuestion: {
      emptyListDesc: "Тут буде відображено список загальних питань від користувачів, коли буде залишено хоча б одне питання."
    },
    isIdea: {
      emptyListDesc: "Тут буде відображено список ідей від користувачів, коли буде залишена хоча б одна ідея."
    },
    complaint: {
      emptyListDesc: "Тут буде відображено список анонімних скарг від користувачів, коли буде залишена хоча б одна скарга."
    },
    message: {
      questionUpdated: "Питання оновлено",
      ideaUpdated: "Ідея оновлена",
      complaintUpdated: "Скарга оновлена",
    }
  },
  course: {
    course: "Курс",
    createCourse: "Створити курс",
    editCourse: "Редагувати курс",
    initiator: "Ініціатор курсу",
    access: "Доступ до курсу",
    shortlyAboutCourse: "Коротко про курс",
    nextDay: "Наступний день доступний, після завершення попереднього та наступного дня",
    addTask: "Додати завдання",
    addTaskHelper: '(щоб додати завдання, попередньо натисніть кнопку "Створити")',
    emptyListTitle: "Список курсів порожній",
    emptyListDesc: "Тут буде відображено список курсів, але зараз він порожній. Натисніть кнопку “Створити” або змініть пошуковий запит.",
    courseList: "Список курсів",
    searchByName: "Пошук за назвою курсу",
    document: "Документ",
    test: "Тест",
    quiz: "Опитування",
    createTask: "Створити завдання",
    editTask: "Редагувати завдання",
    day: "День",
    taskType: "Тип завдання",
    searchTaskByType: "Пошук завдання за типом",
    numDay: " день",
    nextDayAvailable: "Наступний день доступний:",
    afterEndPrevious: "Після завершення попереднього",
    andStartNextDay: "Після завершення попереднього та настання наступного дня",
    startDate: "Дата початку",
    endDate: "Дата завершення",
    period: "Період",
    message: {
      courseUpdated: "Курс оновлено",
      courseCreated: "Курс створено",
      courseRemoved: "Курс видалено",
      taskUpdated: "Завдання оновлено",
      taskCreated: "Завдання створено",
      taskRemoved: "Завдання видалено",
    }
  },
  analytics: {
    analytics: "Аналітика",
    generalAnalytics: "Загальна аналітика",
    all: "Всі",
    active: "Активні",
    working: "Працюють",
    userActivity: "Активність користувачів",
    inRealTime: 'В реальному часі',
    updateInfo: 'Оновлюється кожні 24 години',
    complete: 'Виконано',
    failed: 'Неуспішні',
    analyticsPassCourse: "Аналітика успішности проходження курсів",
    analyticsCourse: "Аналітика курсів",
    analyticsKnowledge: "Аналітика використання Бази знань",
    documents: "Документи",
    news: "Новини",
    promotion: "Акції",
    events: "Події",
    usersInBd: "Користувачів по Базі знань",
    serachRequests: "Аналітика пошукових запитів", 
    analyticsUser: "Аналітика користувачів",
    analyticsResult: "Аналітика результатів тестування",
    test: "Тест",
    quiz: "Опитування",
    course: "Курс",
    analyticsContent: "Аналітика контенту",
    analyticsAdvert: "Аналітика оголошень",
    analyticsProcess: "Аналітика частоти натискання",
    analyticsBadge: "Аналітика гейміфікації",
    timeContent: "Час, проведений на різних типах контенту",
    timeAdvert: "Час, проведений на оголошеннях",
    myProcess: "Мої процеси",
    sections: "Розділи, що знаходяться в профілі користувача",
    feedback: "Зворотний зв'язок",
    personalReserve: "Кадровий резерв",
    checkin: "Checkin",
    result: "Результат",
    productRating: "Рейтинг товарів",
    allProducts: "Всі товари",
    name: "НАЗВА",
    price: "ЦІНА",
    amount: "КІЛЬКІСТЬ",
  },
  trainee: {
    trainees: "Стажерам",
    traineesTasks: "Список завдань для стажерів",
    createTraineeTask: "Додати завдання для стажерів",
    position: "Посада",
    emptyDesc: "Тут буде відображено список завдань для стажерів, але зараз він порожній.",
    taskAdded: "Завдання додано"
  },
  access: {
    accessRemoved: "Успішно видалено",
    searchUsers: "Пошук користувачiв",
    serachDepartment: "Пошук за назвою департаменту",
    searchPosition: "Пошук за назвою посади",
    deleteAll: "Видалити всі доступи",
  },
  assign: {
    assignRemoved: "Успішно видалено",
    deleteAll: "Видалити всі призначення",
  }
};
