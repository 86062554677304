export const getStyles = () => {
    const styles = {
        createButton: {
            borderRadius: '10px',
            width: '130px',
            color: '#FFFFFF',
            fontWeight: 600,
            backgroundColor: '#F1594E',
            textTransform: 'none',
            '&:hover': {
                backgroundColor: '#d85046'
            }
        },
        cancelButton: {
            borderRadius: '10px',
            color: '#F1594E',
            fontWeight: 600,
            borderColor: '#F1594E',
            textTransform: 'none',
            '&:hover': {
                borderColor: '#d85046'
            }
        }
    };
    return styles;
};