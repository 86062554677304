import { Button, CircularProgress, Dialog, IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from "react-i18next";
import { getStyles } from "./styles";
import { tokens } from "../../../../../../../locales/tokens";
import { TimePicker } from "../../../../../../../ui-kit/timePicker";
import { useCourseReport } from "../../../presenter/useCourseReport";

interface IProps {
    value: any;
    isOpen: boolean;
    onClose: () => void;
};

export const ReportModal: FC<IProps> = ({ isOpen, onClose, value }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { startDate, endDate, handleStartDate, handleUpToDate, handleUploadReport, isLoading, onCloseModal } = useCourseReport(value, onClose);

    return (
        <Dialog open={isOpen} onClose={onCloseModal} maxWidth='sm' fullWidth sx={{ borderRadius: '20px' }}>
            <Stack m={4} spacing={3}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" fontWeight={600}>
                        {t(tokens.documents.uploadReport)}
                    </Typography>
                    <IconButton onClick={onClose} sx={{ position: 'relative', top: '-20px', right: '-20px' }}>
                        <SvgIcon><ClearIcon /></SvgIcon>
                    </IconButton>
                </Stack>
                <Stack mt={3} direction="row" justifyContent="space-between" spacing={2} alignItems="end">
                    <Stack flex={1}>
                        <Typography variant="h6" mb={2}>{t(tokens.course.period)}</Typography>
                        <TimePicker value={startDate} handleDate={handleStartDate} size="small" label={t(tokens.course.startDate)} />
                    </Stack>
                    <Stack flex={1}>
                        <TimePicker value={endDate} handleDate={handleUpToDate} size="small" isDisabled={!startDate} label={t(tokens.course.endDate)} minDate={startDate && new Date(startDate) || undefined} />
                    </Stack>
                </Stack>
                <Stack direction='row' mt={3} spacing={3} pt={3}>
                    <Button
                        variant="contained"
                        sx={styles.createButton}
                        onClick={handleUploadReport}
                        disabled={!(startDate && endDate) || isLoading}
                    >
                        {t(tokens.common.upload)}
                    </Button>
                    <Button
                        variant="outlined"
                        sx={styles.cancelButton}
                        onClick={onCloseModal}
                    >
                        {t(tokens.common.cancel)}
                    </Button>
                </Stack>
            </Stack>
        </Dialog >
    )
};