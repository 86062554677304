import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { uploadCourseReport } from "../../../../../store/course/useCases/uploadCourseReport/action";
import { useSelector } from "react-redux";
import { selectCourse } from "../../../../../store/course/repository/selector";
import toast from "react-hot-toast";

export const useCourseReport = (value: any, onClose: () => void) => {
    const dispatch = useDispatch();

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [updateClick, setUpdateClick] = useState(false);
    const { isLoading, uploadReportError } = useSelector(selectCourse);

    useEffect(() => {
        if (!isLoading && updateClick) {
            if (uploadReportError) {
                toast.error("Даних за цей період не знайдено")
            } else {
                toast.success("Звіт завантажено");
                onCloseModal();
            }
            setUpdateClick(false);
        }
    }, [uploadReportError, isLoading]);
    
    const handleStartDate = useCallback((date: Date | null): void => {
        const currentDate: any = date?.valueOf() || null;
        setStartDate(currentDate);
    }, [setStartDate]);

    const handleUpToDate = useCallback((date: Date | null): void => {
        const currentDate: any = date?.valueOf() || null;
        setEndDate(currentDate);
    }, [setEndDate]);

    const handleUploadReport = async () => {
        const { id, name } = value;
        await dispatch(uploadCourseReport({ id, name, startDate, endDate }));
        setUpdateClick(true);
    };

    const onCloseModal = () => {
        setStartDate(null);
        setEndDate(null);
        onClose();
    };

    return { startDate, endDate, handleStartDate, handleUpToDate, handleUploadReport, isLoading, onCloseModal }
};